import React from 'react';
import { Admin, Resource } from 'react-admin';
import feathers from '@feathersjs/client';
import rest from '@feathersjs/rest-client';
import { authProvider, dataProvider, i18nProvider } from './providers';
import { Login } from './components';
import * as resources from './components/resources';
import config from './config'

const app = feathers();
const restClient = rest(`${config.API}/admin`);
app.configure(restClient.fetch(window.fetch));
app.configure(feathers.authentication());
app.authenticate();
const App = () => (
  <Admin
    i18nProvider={i18nProvider}
    loginPage={Login}
    dataProvider={dataProvider(app)}
    authProvider={authProvider(app)}
  >
    <Resource {...resources.articlesProps} />
    <Resource {...resources.testimonialsProps} />
    <Resource {...resources.messagesProps} />
    <Resource {...resources.simulacaoProps}/>
    <Resource {...resources.usersProps} />
  </Admin>
);

export default App;