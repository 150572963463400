import React from 'react';
import { SimpleForm, ImageField, ImageInput, TextInput, required, Labeled } from 'react-admin';
import config from '../../../config';

export default ({ hasCreate, hasEdit, hasList, hasShow, ...props }) => (
	<SimpleForm {...props} redirect="list">
		<TextInput fullWidth label="Nome" source="name" validate={required()} />
		<TextInput fullWidth label="Cidade" source="city" />
		<TextInput fullWidth multiline label="Texto" source="text" validate={required()} />
		{props.record.image && (
			<Labeled label="Imagem atual">
				<img src={config.API + props.record.image.url} alt={props.record.image.originalName} style={{ maxHeight: '10em' }} title={props.record.image.originalName} />
			</Labeled>
		)}
		<ImageInput label="Imagem" source="image" accept="image/*" >
			<ImageField source="fullUrl" title="originalName" />
		</ImageInput>
	</SimpleForm>
);
