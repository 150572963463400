import React from 'react';
import { SimpleForm, TextInput, required } from 'react-admin';

export default props => (
	<SimpleForm {...props} redirect="list">
		<TextInput fullWidth label="Nome" source="name" validate={required()} />
		<TextInput fullWidth label="Email" source="email" validate={required()} />
		{!props.id && (
			<TextInput
				fullWidth
				label="Senha"
				source="password"
				autoComplete="off"
				validate={props.isCreating ? required() : null}
				type="password"
			/>
		)}
	</SimpleForm>
);
