import React from 'react';
import { SimpleForm, ImageField, ImageInput, TextInput, required, Labeled } from 'react-admin';
import { CustomRichTextInput } from '../../custom';
import config from '../../../config';

export default ({ hasCreate, hasEdit, hasList, hasShow, ...props }) => (
	<SimpleForm {...props} redirect="list">
		<TextInput fullWidth label="Título" source="title" validate={required()} />
		<TextInput fullWidth label="Slug" source="slug" validate={required()} />
		<TextInput fullWidth label="Descrição" source="description" validate={required()} />
		{props.record.image && (
			<Labeled label="Imagem atual">
				<img src={config.API + props.record.image.url} alt={props.record.image.originalName} style={{ maxHeight: '10em' }} title={props.record.image.originalName} />
			</Labeled>
		)}
		<ImageInput label="Imagem" source="image" accept="image/*" validate={required()} >
			<ImageField source="fullUrl" title="originalName" />
		</ImageInput>
		<CustomRichTextInput label="Conteúdo" source="content" validate={required()} />
	</SimpleForm>
);