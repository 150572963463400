import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core';

const styles = {
	main: { background: '#333' },
	icon: { display: 'none' }
};

const CustomLoginForm = withStyles({
	button: { background: '#F15922' }
})(LoginForm);

const CustomLoginPage = props => (
	<Login loginForm={<CustomLoginForm />} backgroundImage="" {...props} />
);

export default withStyles(styles)(CustomLoginPage);
