import React from 'react';
import { Filter, Datagrid, TextField, DateField, TextInput } from 'react-admin';
import { CustomList } from '../../custom';

const Filters = props => (
	<Filter {...props}>
		<TextInput fullWidth label="Título" source="title[$regex]" />
		<TextInput fullWidth label="Descrição" source="description[$regex]" />
	</Filter>
);

export default props => (
	<CustomList
		filters={<Filters />}
		{...props}
		title={props.options.label}
		sort={{ field: 'createdAt', order: 'DESC' }}
	>
		<Datagrid rowClick="edit">
			<TextField label="Título" source="title" />
			<TextField label="Descrição" source="description" />
			<DateField label="Data" source="createdAt" showTime />
		</Datagrid>
	</CustomList>
);
