import React from 'react';
import { CustomShow } from '../../custom';
import { CardActions, DeleteButton, ListButton, SimpleShowLayout, DateField, TextField } from 'react-admin';

const Actions = ({ basePath, data, resource }) => (
	<CardActions>
		<ListButton basePath={basePath} />
		<DeleteButton basePath={basePath} record={data} resource={resource} />
	</CardActions>
);

export default props => (
	<CustomShow {...props} title="Visualizar dados da simulação" actions={<Actions />}>
		<SimpleShowLayout>
			<DateField source="createdAt" label="Data" locales="pt-BR" showTime />
			<TextField source="name" label="Nome" />
			<TextField source="email" label="E-mail" />
			<TextField source="phone" label="Telefone" />
			<TextField source="birthday" label="Data de Nascimento" />
      <TextField source="cpf" label="CPF" />
      <TextField source="city" label="Cidade" />
      <TextField source="type" label="Tipo de Aquisição"/>
      <TextField source="income" label="Renda"/>
      <TextField source="estimatedValue" label="Valor Estimado" />
      <TextField source="value" label="Valor de Entrada" />
      <TextField source="moreThanOne" label="Mais de um comprador ou dependente"/>
      <TextField source="otherProperty" label="Possui outro imóvel no município"/>
      <TextField source="fgts" label="Possui 3 anos de FGTS somando-se todos os períodos trabalhados"/>
      <TextField source="publicEmployee" label="Servidor Público"/>
		</SimpleShowLayout>
	</CustomShow>
);
