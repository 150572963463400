import icon from '@material-ui/icons/Person';
import create from './create';
import edit from './edit';
import list from './list';

export { create, edit, list };
export default {
	name: 'testimonials',
	options: { label: 'Depoimentos' },
	icon,
	create,
	edit,
	list
};
